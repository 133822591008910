/* eslint-disable lit/no-template-arrow */
import '@brightspace-ui/core/components/button/button.js';
import { css, html, LitElement } from 'lit';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { testData as data } from '../leaderboard-page/testData.js';
import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

// Wicked so we've got the like "overall" leaderboard"... which then gets subdivided into ranked groups, and within that group we have a list of users...
// So we could retain a "master" list, and still do some kind of sorting & just ask for paginated sections... then you could go up/down the ranked groups

// so we've got an overwhelming leaderboard that contains everyone
// then we've got these thresholds of people, which is what we really care about and will be displaying...

// So do we need to maintain a master list or do we just need to hold onto these various leaderboards?!, then we can make a master list out of all of them
// But even then, each one of these tables should be paginateable... so we can just extend the list....

// so check this...... if we have each list as its own object in the DB and they get updated asynchronously, and we can make the tables paginated...
// then we can have users move up/down from their current rank & load other leaderboards....

// but there is a struggle then to deal with the current leaderboard because it could be entirely full.
// so maybe we also need to be able to load up + down on that table... so we'll initially grab user +- 10 others. Then if they want to see higher
// they can load more... If they want to see lower they can also load more.

// --------------------------- okay up to here is okay.... how to load data?

// next.. the question is how do we want to load in the user information... the various leaderboards should be pretty bare as far as data goes
// But will we have enough info on the leaderboards to populate the tables? probably.

// we'll need User id, name, team, pfp, and then the score. This actual seems pretty reasonable to exist on the leaderboard, and that way
// we won't run into sync issues since the boards get updated asynchronously.

// if we were fetching current user data we could run into a situation where a users xp went up.. but they didn't actually move up in the leaderboard

// --------------------------- okay up to here is okay... how to deal with filters?

// I think for now maybe we don't worry about them since the behaviour will depend a lot on designs decisions
// for now let's build a table that fetches x-y on leaderboard object z... and can paginate up and down

class RankLeaderboard extends LocalizeNova(SkeletonMixin(LitElement)) {
  // okay so the way the leaderboard works is....
  // we have a bunch of "ranks"... these are kind of like leagues
  // each rank gets a a table of users..
  static get properties() {
    return {
      user: { type: Object, attribute: false },
      rankings : { type: Array, attribute: false },
      leaderboardMetadata: { type: Object, attribute: false },
      rankLeaderboard: { type: String },
    };
  }

  get tableHeaders() {
    return ['Rank', 'Department', 'Name', 'Experience'];
  }

  get batchSize() {
    return 5;
  }

  static get styles() {
    return css`
      /* Add styles here */
      .load-more-button-container {
        text-align: center;
        border: var(--d2l-table-border);
      }
      
      ${tableStyles}

      .table-wrapper {
        max-height: 500px;
        height: 100%;
        overflow-y: scroll;
      }

      .rank-heading {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 0.25rem;
      }

      .rank-level-range {
        text-align: center;
        margin-bottom: 0.25rem;
        margin-top: 0;
      }

      #user-row {
        background-color: var(--d2l-color-celestine-plus-2) !important;
      }

      .rank-leaderboard {
        td, th {
         text-align: center;
        }
      }

      .load-more-button {
        width: 100%;
      }

      .rank-leaderboard tbody td, .load-more-button-container {
        border: none;
      }
    `;
  }

  constructor() {
    super();
    this.user = null;
    this.rankings = [];
    this.leaderboardMetadata = null;
  }

  async _loadMore(startRank, endRank, addingToStart) {
    if (startRank < 1) startRank = 1;
    if (endRank > this.leaderboardMetadata.rankSize) endRank = this.leaderboardMetadata.rankSize;
    // endRank doesn't get a -1 because slice isn't end inclusive, but startRank gets - 1 to convert from rank to index
    if (addingToStart) {
      // push new rankings to the front
      this.rankings = [...data[this.rankLeaderboard].rankings.slice(startRank - 1, endRank), ...this.rankings];
    } else {
      this.rankings = [...this.rankings, ...data[this.rankLeaderboard].rankings.slice(startRank - 1, endRank)];
    }
  }

  _getInitialRankings() {
    const lastPosition = this.leaderboardMetadata.rankSize;
    // so we want to display 10 and if the diff is less than 10 we'll start with those
    const maxToDisplay = 5;

    let start = 0;
    let end = 0;

    if (lastPosition <= maxToDisplay) {
      end = maxToDisplay;
    } else {
      // we want to find the middle
      const middle = Math.floor(lastPosition / 2);
      start = middle - Math.floor(maxToDisplay / 2);
      end = middle + Math.floor(maxToDisplay / 2) + (maxToDisplay % 2);
    }
    return data[this.rankLeaderboard].rankings.slice(start, end);
  }

  firstUpdated() {
    this.leaderboardMetadata = data[this.rankLeaderboard].metadata;
    this.rankings = this._getInitialRankings();

    // set styles on the pesky shadowroot buttons
    setTimeout(() => { /* code for checking DOM styles */
      const loadMoreButtons = this.shadowRoot.querySelectorAll('.load-more-button');
      loadMoreButtons.forEach(button => {
        button.shadowRoot.firstElementChild.style.width = '100%';
      });

      // bubble up a reference to the user row
      if (this.rankLeaderboard === 'Navigator') {
        this.dispatchEvent(new CustomEvent('user-row-loaded', {
          detail: this.shadowRoot.querySelector('#user-row'),
          bubbles: true,
          composed: true,
        }));
      }
    }, 10);
  }

  _loadMoreRowsButton(loadHigherRanks) {
    if (!loadHigherRanks && this.rankings[0]?.rank > 1) {
      // get our range of ranks to add
      const startRank = (this.rankings[0]?.rank - (this.batchSize));
      const endRank = (this.rankings[0]?.rank - 1);
      return html`
        <tr>
          <td class="load-more-button-container" colspan="${this.tableHeaders.length}">
            <d2l-button-subtle 
              primary=true 
              id="load-higher-ranks-button"
              class="load-more-button"
              icon="tier1:arrow-toggle-up" 
              @click=${() => {this._loadMore(startRank, endRank, true);}} 
              text="Load Higher Ranks"
              >
            </d2l-button-subtle>
          </td>
        </tr>
      `;
    } else if (loadHigherRanks && this.rankings[this.rankings.length - 1]?.rank !== this.leaderboardMetadata?.rankSize) {
      const startRank = this.rankings[this.rankings.length - 1]?.rank + 1;
      const endRank = this.rankings[this.rankings.length - 1]?.rank + (this.batchSize);
      return html`
        <tr>
          <td class="load-more-button-container" colspan="${this.tableHeaders.length}">
            <d2l-button-subtle 
              primary=true 
              id="load-lower-ranks-button"
              class="load-more-button"
              icon="tier1:arrow-toggle-down" 
              @click=${() => {this._loadMore(startRank, endRank, false);}} 
              text="Load Lower Ranks">
            </d2l-button-subtle>
          </td>
        </tr>
      `;
    }
  }

  render() {
    return html`
      <div>
        <h2 class="rank-heading">*** ${this.leaderboardMetadata?.rankTitle} ***</h2>
        <p class="rank-level-range">${this.leaderboardMetadata?.experienceBottom}xp - ${this.leaderboardMetadata?.experienceTop}xp</p>
        <d2l-table-wrapper type="light" class="table-wrapper" sticky-headers=true>
          <table class="d2l-table rank-leaderboard">
            <thead>
              <tr>
                ${this.tableHeaders.map(header => html`<th>${header}</th>`)}
              </tr>
            </thead>
            ${this._loadMoreRowsButton(false)}
            <tbody>
              ${this.rankings.map(user => html`
                <tr id="${user.name === 'Witch-king of Angmar' ? 'user-row' : ''}">
                  ${this.tableHeaders.map(header => html`<td>${user[header.toLowerCase()]}</td>`)}
                </tr>
              `)}
            </tbody>
            ${this._loadMoreRowsButton(true)}
          </table>
        </d2l-table-wrapper>
      </div>
    `;
  }
}

window.customElements.define('rank-leaderboard', RankLeaderboard);
