import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import './components/user-achievements/user-achievements.js';
import './components/user-points/user-points.js';
import './components/leaderboard-page/leaderboard-page.js';

import '../../users/profile-image/profile-image.js';

import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';

import { bodyCompactStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';

export default class UserProfile extends LocalizeNova(SkeletonMixin(RequesterMixin(LitElement))) {
  static get properties() {
    return {
      _user: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [heading3Styles, bodyCompactStyles, css`
      .profile-page {
        display: flex;
        flex-direction: row;
      }

      .profile-page .profile-side {
        background: white;
        border: 1px solid var(--d2l-color-mica);
        border-radius: 6px;
        flex: 1;
        margin-right: 20px;
        min-height: 700px;
        padding: 12px;
      }

      .profile-card {
        align-items: center;
        border-bottom: 1px solid var(--d2l-color-gypsum);
        display: flex;
        flex-direction: row;
        justify-content: start;
        padding-bottom: 12px;
      }

      .profile-card .profile-info {
        margin-left: 10px;
      }

      .profile-card .profile-info h2 {
        margin: 0;
      }

      .profile-card .profile-info p {
        margin: 0;
      }

      .profile-page .profile-main {
        flex: 3;
      }

    `];
  }

  constructor() {
    super();
    this._user = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  firstUpdated() {
    super.firstUpdated();
    this._user = this.session._user;
  }

  render() {

    return html`
      <div class="profile-page">
        <div class="profile-side">
          <div class="profile-card">
            <profile-image .user=${this._user} hide-profile-text circle></profile-image>
            <div class="profile-info">
              <h2 class="d2l-heading-3">${this._user?.firstName} ${this._user?.lastName}</h2>
              <p class="d2l-body-compact">${this._user?.department}</p>
              <p class="d2l-body-compact">${this._user?.title}</p>
            </div>
          </div>
          <user-points .user="${this._user}"></user-points>
        </div>
        <div class="profile-main">
          <d2l-tabs>
            <d2l-tab-panel selected text="My Learning" >
              <div class="achievements-column">
                <user-achievements .user="${this._user}"></user-achievements>
              </div>
            </d2l-tab-panel>
            <d2l-tab-panel text="My Feed">
            </d2l-tab-panel>
            <d2l-tab-panel text="Leaderboard">
               <leaderboard-page .user="${this._user}"></leaderboard-page>
            </d2l-tab-panel>
          </d2l-tabs>
        </div>
      </div>
    `;
  }
}

window.customElements.define('user-profile', UserProfile);

