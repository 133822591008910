import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

class UserPoints extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      user: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return css`
      /* Add styles here */
    `;
  }

  constructor() {
    super();
    this.user = null;
  }

  render() {
    return html`
      <div>
        <h2>my points</h2>
        <p>I have <strong>${this.user?.points}</strong> points</p>
      </div>
    `;
  }
}

window.customElements.define('user-points', UserPoints);

