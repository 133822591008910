import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

import '../rank-leaderboard/rank-leaderboard.js';

class LeaderboardPage extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      user: { type: Object, attribute: false },
      userRowReference: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return css`
      /* Add styles here */

      .user-card {
        padding: 0.6rem;
        text-align: center;
        border: 1px solid var(--d2l-color-mica);
        border-radius: 6px;
        h3 {
          margin: 0;
        }
      }
    `;
  }

  get ranks() {
    return ['LegendaryExplorer', 'Pioneer', 'Voyager', 'Navigator'];
  }

  _scrollToUserRow() {
    if (this.userRowReference) {
      this.userRowReference.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  constructor() {
    super();
    this.user = null;
    this.userRowReference = null;
    this.addEventListener('user-row-loaded', e => {this.userRowReference = e.detail;});
  }

  render() {
    return html`
      <div>
        <div class="user-card">
            <h3> You are a </h3>
            <h1> *** Navigator *** </h1>
            <d2l-button-subtle @click=${this._scrollToUserRow} id="rank" text="Ranked #22"></d2l-button-subtle>
        </div>
        ${this.ranks.map(rank => html`
            <rank-leaderboard .rankLeaderboard=${rank} .user=${this.user}></rank-leaderboard>
        `)}
      </div>
    `;
  }
}

window.customElements.define('leaderboard-page', LeaderboardPage);

