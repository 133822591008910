import { css, html, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../../../mixins/localize-nova/localize-nova.js';

class UserAchievements extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      user: { type: Object, attribute: false },
      _achievements: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return css`
      /* Add styles here */
    `;
  }

  constructor() {
    super();
    this.user = null;
    this._achievements = [];
  }

  updated(changedProperties) {
    if (changedProperties.has('user')) {
      this._achievements = this.user?.achievements || [];
    }
  }

  render() {
    return html`
      <div>
        <h2>my achievements</h2>
        <ul>
        ${repeat(this._achievements, achievement => achievement.id, achievement => html`
          <li>${achievement.name}</li>
        `)}
        </ul>
      </div>
    `;
  }
}

window.customElements.define('user-achievements', UserAchievements);
